import React from 'react';
import { SectionContainer } from '../../Components';
import { Spike } from './Spike';
import { Ibcos } from './Ibcos';
import { Typography } from '@mui/material';
import { Obverse } from './Obverse';

export function Work() {
    return (
        <SectionContainer>
            <Typography variant="h3">Where I've Worked</Typography>
            <Spike />
            <Ibcos />
            <Obverse />
        </SectionContainer>
    );
}
