import React, { ReactNode, CSSProperties, RefObject } from 'react';
import { useIsMobile } from '../../Hooks';

export interface ISectionContainerProps {
    children: ReactNode;
    style?: CSSProperties;
    ref?: RefObject<HTMLDivElement>;
}

export function SectionContainer({
    children,
    style,
    ref,
}: ISectionContainerProps) {
    const { isMobile } = useIsMobile();
    const margin = isMobile ? '0px 20px 25px' : '25px 35px 50px 35px';
    return (
        <div ref={ref} style={{ margin, ...style }}>
            {children}
        </div>
    );
}
