import { createTheme } from '@mui/material/styles';
import { Colours } from './colours';

export const desktopTheme = createTheme({
    palette: {
        primary: { main: Colours.primary },
        secondary: { main: Colours.secondary },
        text: { primary: Colours.text },
    },
    typography: (palette) => ({
        h1: {
            color: palette.text.primary,
            fontSize: '3.5rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        h2: {
            color: palette.text.primary,
            fontSize: '2.5rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        h3: {
            color: palette.text.primary,
            fontSize: '2rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        h4: {
            color: palette.text.primary,
            fontSize: '1.25rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        subtitle1: {
            color: palette.text.primary,
            fontFamily: `'Roboto Mono', monospace`,
            opacity: 0.7,
            lineHeight: 1.5,
        },
        body2: {
            color: palette.text.primary,
            fontFamily: `'Roboto Mono', monospace`,
            fontSize: '1rem',
        },
        subtitle2: {
            color: palette.text.primary,
            fontFamily: `'Roboto Mono', monospace`,
            fontSize: '1rem',
        },
        button: {
            color: palette.primary.main,
            fontFamily: `'Roboto Mono', monospace`,
            textTransform: 'none',
            fontSize: '1.25rem'
        },
    }),
});

export const mobileTheme = createTheme({
    palette: {
        primary: { main: Colours.primary },
        secondary: { main: Colours.secondary },
        text: { primary: Colours.text },
    },
    typography: (palette) => ({
        h1: {
            color: palette.text.primary,
            fontSize: '3.5rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        h2: {
            color: palette.text.primary,
            fontSize: '2rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        h3: {
            color: palette.text.primary,
            fontSize: '2rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        h4: {
            color: palette.text.primary,
            fontSize: '1.25rem',
            fontFamily: `'Roboto Mono', monospace`,
        },
        subtitle1: {
            color: palette.text.primary,
            fontFamily: `'Roboto Mono', monospace`,
            opacity: 0.7,
            lineHeight: 1.5,
        },
        body2: {
            color: palette.text.primary,
            fontFamily: `'Roboto Mono', monospace`,
            fontSize: '1rem',
        },
        subtitle2: {
            color: palette.text.primary,
            fontFamily: `'Roboto Mono', monospace`,
            fontSize: '1rem',
        },
        button: {
            color: palette.primary.main,
            fontFamily: `'Roboto Mono', monospace`,
            textTransform: 'none',
            fontSize: '1.1rem'
        },
    }),
})