import React from 'react';
import { Typography } from '@mui/material';

import './style.scss';

export interface ILinkProps {
    onClick: () => void;
    label: string;
}

export function Link({ onClick, label }: ILinkProps) {
    return (
        <div
            className="link"
            style={{ margin: '0px 10px', cursor: 'pointer' }}
            onClick={onClick}
        >
            <Typography variant="button">{label}</Typography>
        </div>
    );
}
