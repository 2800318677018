import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

export * from './CSharpIcon';
export * from './TypescriptIcon';
export * from './FSharpIcon';
export * from './HTMLIcon';
export * from './CSSIcon';
export * from './CPlusPlusIcon';

export * from './DotNetIcon';
export * from './ReactIcon';
export * from './AngularIcon';
export * from './VueIcon';
export * from './ReactNativeIcon';
export * from './UnityIcon';
export * from './XamarinIcon';
export * from './GraphQLIcon';
export * from './PostgresIcon';
export * from './MongoIcon';
export * from './MySQLIcon';

export * from './VisualStudioIcon';
export * from './GitIcon';
export * from './JiraIcon';
export * from './JenkinsIcon';
export * from './PostmanIcon';
export * from './DockerIcon';
export * from './GulpIcon';

export interface IIconProps {
    size?: number;
}

export interface IIconContainerProps {
    children: ReactNode;
    label: string;
    size?: number;
}

export function IconContainer({ children, label, size }: IIconContainerProps) {
    return (
        <div
            style={{
                width: `${size}px`,
                margin: '15px 20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div style={{ marginBottom: '5px' }}>{children}</div>
            <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                {label}
            </Typography>
        </div>
    );
}
