import React from 'react';
import { SectionContainer } from '../../Components';
import { Typography, Stack } from '@mui/material';
import profilePicture from '../../Assets/profile.jpg';
import { Colours } from '../../Util';
import { useIsMobile } from '../../Hooks';

export function Profile() {
    const { isMobile } = useIsMobile();
    const margin = isMobile ? '0px 20px 25px' : '0px 100px 35px 100px';
    return (
        <SectionContainer style={{ margin }}>
            <div>
                <Stack direction={isMobile ? 'column' : 'row'}
                sx={{ marginBottom: '20px' }}>
                    <div style={{ flex: '1 1 0', textAlign: isMobile ? 'center' : 'start', marginBottom: '25px' }}>
                        <Typography variant="h1">Rob Tuvey</Typography>
                        <Typography variant="h2">Software Engineer</Typography>
                    </div>
                    <img
                        src={profilePicture}
                        style={{
                            width: '200px',
                            borderRadius: '105px',
                            border: `5px ${Colours.primary} solid`,
                            alignSelf: isMobile ? 'center' : 'end'
                        }}
                    />
                </Stack>
                <Typography variant="body2">
                    Hi! I'm a passionate and creative full-stack software
                    engineer with a demonstrated history of working in the
                    information technology industry. I specialize in making
                    interactive applications and reliable web services. With
                    experience with various front and back end technologies. I'm
                    currently focused on making resident engagement and property
                    management software at{' '}
                    <a
                        style={{ color: Colours.primary }}
                        href="https://www.spikeglobal.com/"
                    >
                        Spike Global
                    </a>
                    .
                    <br /> <br />
                    I'm a strong engineering professional with a drive to keep
                    learning and improving.
                </Typography>
            </div>
        </SectionContainer>
    );
}
