import React from 'react';
import { IconContainer, IIconProps } from '..';

const DEFAULT_SIZE = 24;

export function VisualStudioIcon({ size }: IIconProps) {
    return (
        <IconContainer label="Visual Studio" size={size}>
            <svg
                width={size ?? DEFAULT_SIZE}
                height={size ?? DEFAULT_SIZE}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-0.002 -0.003 500.002 516.812"
            >
                <path
                    d="M483.292 64.07L372.104 19.499l-194.08 189.002-116.873-88.967-44.441 22.351-.044 233.313 44.232 22.189 116.778-88.894 194.557 188.829 111.101-44.397-.042-388.855z"
                    fill="#68217a"
                    stroke="#fff"
                    strokeWidth="33.33"
                />
                <path
                    d="M361.037 175.128L249.98 258.15l111.101 83.673-.044-166.695zM72.192 208.596l.066 99.858 55.483-50.267-55.549-49.591z"
                    fill="#fff"
                />
            </svg>
        </IconContainer>
    );
}
