import React from 'react';
import { IconContainer, IIconProps } from '..';

const DEFAULT_SIZE = 24;

export function FSharpIcon({ size }: IIconProps) {
    return (
        <IconContainer label="F#" size={size}>
            <svg
                width={size ?? DEFAULT_SIZE}
                height={size ?? DEFAULT_SIZE}
                viewBox="0 0 256 243"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
            >
                <path
                    d="M0 121.492L121.492 0v60.746l-60.746 60.746 60.746 60.745v60.746L0 121.492z"
                    fill="#378BBA"
                />
                <path
                    d="M78.102 121.492l43.39-43.39v86.78l-43.39-43.39z"
                    fill="#378BBA"
                />
                <path
                    d="M256 121.492L130.17 0v60.746l60.745 60.746-60.746 60.745v60.746L256 121.492z"
                    fill="#30B9DB"
                />
            </svg>
        </IconContainer>
    );
}
