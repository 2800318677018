import React, { useRef, RefObject } from 'react';
import { Colours } from './Util';
import {
    ThemeProvider,
    Stack,
    Typography,
    Link as MUILink,
} from '@mui/material';
import { Profile, Skills, Work, Projects, Education } from './Sections';
import { Link } from './Components';
import { useIsMobile, useTheme } from './Hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function App() {
    const profileRef = useRef<HTMLDivElement>(null);
    const skillsRef = useRef<HTMLDivElement>(null);
    const workRef = useRef<HTMLDivElement>(null);
    const educationRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useIsMobile();
    const theme = useTheme();

    const goToRef = (ref: RefObject<HTMLDivElement>) => {
        return () => {
            ref.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        };
    };

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                backgroundColor: Colours.background,
                overflowX: 'hidden',
            }}
        >
            <ThemeProvider theme={theme}>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    sx={{
                        position: isMobile ? 'static' : 'sticky',
                        top: 0,
                        backgroundColor: Colours.background,
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', margin: isMobile ? '5px 10px 20px' : '5px 0px', justifyContent: 'space-between' }}>
                        <Link onClick={goToRef(profileRef)} label="Profile" />
                        <Link onClick={goToRef(skillsRef)} label="Skills" />
                        <Link onClick={goToRef(workRef)} label="Work" />
                        <Link
                            onClick={goToRef(educationRef)}
                            label="Education"
                        />
                    </div>
                    <Stack
                        style={{ margin: '15px 25px 0px 25px', justifyContent: 'center' }}
                        direction="row"
                        spacing={7.5}
                    >
                        <MUILink
                            href="https://robtuvey.co.uk/RobTuvey-CV.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                            underline="hover"
                            className="icon-button"
                        >
                            <Stack spacing={2} direction="row">
                                <Typography variant="button">CV</Typography>
                                <FontAwesomeIcon
                                    style={{ cursor: 'pointer' }}
                                    icon={solid('file')}
                                    color={Colours.primary}
                                    size="2x"
                                />
                            </Stack>
                        </MUILink>
                        <MUILink
                            href="https://gitlab.com/RobTuvey"
                            underline="hover"
                            className="icon-button"
                        >
                            <Stack spacing={2} direction="row">
                                <Typography variant="button">GitLab</Typography>
                                <FontAwesomeIcon
                                    style={{ cursor: 'pointer' }}
                                    icon={brands('gitlab')}
                                    color={Colours.primary}
                                    size="2x"
                                />
                            </Stack>
                        </MUILink>
                    </Stack>
                </Stack>
                <div
                    style={{
                        maxWidth: '1024px',
                        margin: isMobile ? '0px auto 20px' : '50px auto 20px auto',
                        boxShadow: isMobile
                            ? 'none'
                            : '10px 10px 33px #181718, -10px -10px 33px #625b60',
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ marginTop: isMobile ? '40px' : '75px' }}>
                        <div ref={profileRef}>
                            <Profile />
                        </div>
                        <div ref={skillsRef}>
                            <Skills />
                        </div>
                        <div ref={workRef}>
                            <Work />
                        </div>
                        <div ref={educationRef}>
                            <Education />
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default App;
