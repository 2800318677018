import React from 'react';
import { SectionContainer } from '../../../Components';
import { Typography, Stack } from '@mui/material';
import { useIsMobile } from '../../../Hooks';

export function Obverse() {
    const { isMobile } = useIsMobile();

    return (
        <SectionContainer style={{ margin: '25px 0px' }}>
            <Stack direction={ isMobile ? 'column' : 'row'} sx={{ justifyContent: 'space-between'}}>
                <Typography variant="h4">Obverse Studios</Typography>
                <Typography variant="subtitle1">
                    September 2016 - September 2017
                </Typography>
            </Stack>
            <Typography style={{ marginBottom: '5px' }} variant="subtitle1">
                Programmer
            </Typography>
            <ul style={{ paddingInlineStart: isMobile ? '15px' : '40px' }}>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Formed games studio with other students as part of an
                    industry placement year at university. Designed and produced
                    mobile game TimeBreaker using <strong>C#</strong> and{' '}
                    <strong>Unity</strong>. Releasing the game to the Apple and
                    Google Play Store.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Handled release process via the Apple and Google Play
                    release dashboards.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    TimeBreaker was featured on Google Play Store’s ‘Indie
                    Picks’ helping the game to get over 20,000 downloads on
                    Android.
                </Typography>
            </ul>
        </SectionContainer>
    );
}
