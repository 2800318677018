import { useState, useEffect } from "react";

export function useIsMobile() {
    const [width, setWidth] = useState(window.innerWidth);

    const handleSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleSizeChange);
        return () => {
            window.removeEventListener('resize', handleSizeChange);
        }
    }, []);

    return {
        isMobile: width <= 768
    };
}