import React from 'react';
import { SectionContainer } from '../../Components';
import { Typography, Chip, Stack } from '@mui/material';
import { useIsMobile } from '../../Hooks';

export function Education() {
    const { isMobile } = useIsMobile();

    return (
        <SectionContainer>
            <Typography variant="h3" style={{ marginBottom: '25px' }}>
                What I've Studied
            </Typography>
            <Stack direction={isMobile ? 'column' : 'row' } sx={{ justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: isMobile ? 'space-between' : 'start' }}>
                    <Typography variant="h4" style={{ marginRight: '10px' }}>
                        BSc Games Programming
                    </Typography>
                    <Chip
                        sx={{ borderRadius: '5px' }}
                        color="primary"
                        label="First Class"
                        size="small"
                    />
                </div>
                <Typography variant="subtitle1">2014-2018</Typography>
            </Stack>
            <Typography variant="subtitle1">Bournemouth University</Typography>
        </SectionContainer>
    );
}
