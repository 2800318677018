import React from 'react';
import { SectionContainer } from '../../Components';
import { Typography } from '@mui/material';
import {
    CSharpIcon,
    TypescriptIcon,
    FSharpIcon,
    HTMLIcon,
    CSSIcon,
    CPlusPlusIcon,
    DotNetIcon,
    ReactIcon,
    AngularIcon,
    VueIcon,
    ReactNativeIcon,
    XamarinIcon,
    GraphQLIcon,
    PostgresIcon,
    MongoIcon,
    MySQLIcon,
    GitIcon,
    JiraIcon,
    JenkinsIcon,
    PostmanIcon,
    DockerIcon,
    GulpIcon,
    VisualStudioIcon,
    UnityIcon,
} from '../../Components/Icons';
import { useIsMobile } from '../../Hooks';

export function Skills() {
    const { isMobile } = useIsMobile();
    const size = isMobile ? 48 : 64;
    return (
        <SectionContainer>
            <Typography variant="h3" style={{ marginBottom: '10px' }}>
                Skills
            </Typography>
            <Typography variant="h4">Languages</Typography>
            <div style={{ display: 'flex', marginBottom: '10px', flexWrap: 'wrap' }}>
                <CSharpIcon size={size} />
                <TypescriptIcon size={size} />
                <FSharpIcon size={size} />
                <HTMLIcon size={size} />
                <CSSIcon size={size} />
                <CPlusPlusIcon size={size} />
            </div>
            <Typography variant="h4">Frameworks</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <DotNetIcon size={size} />
                <ReactIcon size={size} />
                <AngularIcon size={size} />
                <VueIcon size={size} />
                <ReactNativeIcon size={size} />
                <UnityIcon size={size} />
                <XamarinIcon size={size} />
                <GraphQLIcon size={size} />
                <PostgresIcon size={size} />
                <MongoIcon size={size} />
                <MySQLIcon size={size} />
            </div>
            <Typography variant="h4">Tools</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <VisualStudioIcon size={size} />
                <GitIcon size={size} />
                <JiraIcon size={size} />
                <JenkinsIcon size={size} />
                <PostmanIcon size={size} />
                <DockerIcon size={size} />
                <GulpIcon size={size} />
            </div>
        </SectionContainer>
    );
}
