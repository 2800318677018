import React from 'react';
import { SectionContainer } from '../../../Components';
import { Typography, Stack } from '@mui/material';
import { useIsMobile } from '../../../Hooks';

export function Ibcos() {
    const { isMobile } = useIsMobile();

    return (
        <SectionContainer style={{ margin: '25px 0px 25px 10px' }}>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                sx={{ justifyContent: 'space-between' }}
            >
                <Typography variant="h4">Ibcos Computers</Typography>
                <Typography variant="subtitle1">
                    June 2018 - September 2020
                </Typography>
            </Stack>
            <Typography style={{ marginBottom: '5px' }} variant="subtitle1">
                Software Engineer
            </Typography>
            <ul style={{ paddingInlineStart: isMobile ? '15px' : '40px' }}>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Developed core features of Gold Service mobile application.
                    Consisting of a mobile app for agricultural engineers,
                    written in <strong>C#</strong> utilizing the{' '}
                    <strong>Xamarin Forms</strong> framework, with{' '}
                    <strong>Sqlite</strong>. Complemented by a web application
                    for managers written with <strong>JavaScript</strong> and{' '}
                    <strong>VueJS</strong>.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Developed <strong>.Net</strong> API to provide functionality
                    to accommodate mobile app and web front end, built on top of
                    a hybrid data store of <strong>MySQL</strong> database and
                    Isams. Ensuring high test coverage and coding standards are
                    met throughout. Took part in overhaul to move the API from
                    .Net Framework to .Net Standard and .Net Core to allow for
                    the implementation of modern technologies to add more high
                    value functionality to the product.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Worked with the legacy <strong>DiBOL</strong> code base,
                    moving features to the web API, implementing new features
                    and bug fixing.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Mentored colleagues in <strong>C#</strong>,{' '}
                    <strong>unit testing</strong> and <strong>SOLID</strong>{' '}
                    design principles. Increasing productivity and code quality.
                </Typography>
            </ul>
        </SectionContainer>
    );
}
