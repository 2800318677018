import React from 'react';
import { IconContainer, IIconProps } from '..';

const DEFAULT_SIZE = 24;

export function GraphQLIcon({ size }: IIconProps) {
    return (
        <IconContainer label="GraphQL" size={size}>
            <svg
                height={size ?? DEFAULT_SIZE}
                width={size ?? DEFAULT_SIZE}
                viewBox="20.8 4.2 358.4 391.6"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g fill="#e535ab">
                    <path d="m57.468 302.66-14.376-8.3 160.15-277.38 14.376 8.3z" />
                    <path d="m39.8 272.2h320.3v16.6h-320.3z" />
                    <path d="m206.348 374.026-160.21-92.5 8.3-14.376 160.21 92.5zm139.174-241.079-160.21-92.5 8.3-14.376 160.21 92.5z" />
                    <path d="m54.482 132.883-8.3-14.375 160.21-92.5 8.3 14.375z" />
                    <path d="m342.568 302.663-160.15-277.38 14.376-8.3 160.15 277.38zm-290.068-195.163h16.6v185h-16.6z" />
                    <path d="m330.9 107.5h16.6v185h-16.6z" />
                    <path d="m203.522 367-7.25-12.558 139.34-80.45 7.25 12.557z" />
                    <path d="m369.5 297.9c-9.6 16.7-31 22.4-47.7 12.8s-22.4-31-12.8-47.7 31-22.4 47.7-12.8c16.8 9.7 22.5 31 12.8 47.7m-278.6-160.9c-9.6 16.7-31 22.4-47.7 12.8s-22.4-31-12.8-47.7 31-22.4 47.7-12.8c16.7 9.7 22.4 31 12.8 47.7m-60.4 160.9c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.8 9.6-38.1 3.9-47.7-12.8m278.6-160.9c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.7 9.6-38.1 3.9-47.7-12.8m-109.1 258.8c-19.3 0-34.9-15.6-34.9-34.9s15.6-34.9 34.9-34.9 34.9 15.6 34.9 34.9c0 19.2-15.6 34.9-34.9 34.9m0-321.8c-19.3 0-34.9-15.6-34.9-34.9s15.6-34.9 34.9-34.9 34.9 15.6 34.9 34.9-15.6 34.9-34.9 34.9" />
                </g>
            </svg>
        </IconContainer>
    );
}
