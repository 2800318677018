import React from 'react';
import { SectionContainer } from '../../../Components';
import { Typography, Stack } from '@mui/material';
import { useIsMobile } from '../../../Hooks';

export function Spike() {
    const { isMobile } = useIsMobile();

    return (
        <SectionContainer style={{ margin: '25px 0px 25px 10px' }}>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                sx={{ justifyContent: 'space-between' }}
            >
                <Typography variant="h4">Spike Global</Typography>
                <Typography variant="subtitle1">
                    September 2020 - Present
                </Typography>
            </Stack>
            <Typography style={{ marginBottom: '5px' }} variant="subtitle1">
                Software Engineer
            </Typography>
            <ul style={{ paddingInlineStart: isMobile ? '15px' : '40px' }}>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Implemented and maintained core product features on resident
                    engagement application. Made up of web front end using{' '}
                    <strong>Angular</strong> and <strong>Typescript</strong>,
                    served up on mobile devices using <strong>Cordova</strong>.
                    A back end <strong>.Net</strong> RESTful API written in{' '}
                    <strong>C#</strong>, interfacing with <strong>Mongo</strong>{' '}
                    and <strong>SQL</strong> databases.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Took ownership of a new reporting feature, designing
                    implementation to meet feature requirements. Considering
                    performance when operating on large data sets of millions of
                    records.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Overhauled mobile booking experience, implementing a new
                    user experience. With new back end implementation backed up
                    by <strong>unit testing</strong>.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Researched important aspects when designing and planning new
                    product. Defining standards and methodologies for developing
                    <strong>React</strong> and <strong>.Net 6</strong> API.
                    Implemented a proof of concept project to demonstrate the
                    use of <strong>F#</strong> in implementing business rules in
                    a functional paradigm.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Utilized a monorepo with <strong>Nx</strong> to create a{' '}
                    <strong>React</strong> web app and
                    <strong>React Native</strong> mobile apps with shared code.
                    To form the basis of the front end side of a new iteration
                    of the product. With a <strong>.Net 6</strong> API using{' '}
                    <strong>EF Core</strong> and <strong>Postgres</strong> to
                    serve up data to the front end.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Implemented library for generating large amounts of
                    representative data, with meaningful relationships for
                    performance and feature testing.
                </Typography>
                <Typography
                    variant="body2"
                    component="li"
                    style={{ marginBottom: '8px' }}
                >
                    Used <strong>GraphQL</strong> and the{' '}
                    <strong>Hot Chocolate</strong> library to provide domain
                    wide querying functionality to the front end.
                </Typography>
            </ul>
        </SectionContainer>
    );
}
